import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import * as css from "../../components/content.module.css"

function ContactPageKindergarden() {
  return (
    <Layout menuType="kindergarden">
      <SEO title="Kontakt - ZSP Nidek" />
      <div>
        <h1>Kontakt</h1>
        <section>
          <p>Zespół Szkolno- Przedszkolny w Nidku</p>

          <p>im. Mikołaja Kopernika</p>
          <address>
            <p>Nidek, ul. Św. Judy Tadeusza 2</p>

            <p>34-122 Wieprz</p>
          </address>
        </section>
        <section>
          <p>Dyrektor szkoły: mgr Ewa Snażyk- Płonka</p>
        </section>
        <section>
          <div className={css.links}>
            <p>
              tel. <a href="tel:338755811"> (33 prefiks) 875-58-11</a>
            </p>
            <p>
              e-mail:{" "}
              <a href="mailto:sekretariat@zspnidek.wieprz.pl">
                sekretariat@zspnidek.wieprz.pl
              </a>
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPageKindergarden
